import React from 'react';

const NavReact = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9991 17.3733C17.3136 17.3733 18.3791 16.3077 18.3791 14.9933C18.3791 13.6788 17.3136 12.6133 15.9991 12.6133C14.6847 12.6133 13.6191 13.6788 13.6191 14.9933C13.6191 16.3077 14.6847 17.3733 15.9991 17.3733Z"
      fill="#344054"
    />
    <path
      d="M9.33696 19.7252L8.8103 19.5905C4.8983 18.6025 2.6543 16.9225 2.6543 14.9878C2.6543 13.0518 4.89963 11.3732 8.8103 10.3852L9.33696 10.2518L9.48496 10.7732C9.88035 12.1381 10.3869 13.4683 10.9996 14.7505L11.113 14.9878L10.9996 15.2252C10.3863 16.5092 9.8783 17.8398 9.48496 19.2052L9.33696 19.7252V19.7252ZM8.56763 11.5985C5.59563 12.4358 3.7703 13.7132 3.7703 14.9878C3.7703 16.2598 5.5943 17.5385 8.56763 18.3745C8.93163 17.2172 9.37163 16.0852 9.88096 14.9878C9.36794 13.8887 8.92918 12.7563 8.56763 11.5985V11.5985ZM22.6636 19.7252L22.517 19.2025C22.1205 17.8373 21.6135 16.5067 21.001 15.2238L20.8876 14.9878L21.001 14.7492C21.6143 13.4678 22.1196 12.1358 22.517 10.7705L22.6636 10.2505L23.1903 10.3838C27.1036 11.3718 29.3463 13.0505 29.3463 14.9878C29.3463 16.9238 27.1023 18.6025 23.1903 19.5905L22.6636 19.7252V19.7252ZM22.117 14.9878C22.6503 16.1425 23.0943 17.2785 23.433 18.3745C26.4063 17.5385 28.2316 16.2598 28.2316 14.9878C28.2316 13.7132 26.4076 12.4372 23.433 11.5985C23.0693 12.756 22.6297 13.8882 22.117 14.9878V14.9878Z"
      fill="#344054"
    />
    <path
      d="M8.55902 11.5935L8.41236 11.0735C7.31102 7.19616 7.64435 4.41883 9.32835 3.45083C10.975 2.49749 13.6244 3.62416 16.399 6.47083L16.7777 6.86016L16.399 7.24816C15.4135 8.27308 14.5128 9.37625 13.7057 10.5468L13.555 10.7628L13.2937 10.7842C11.8745 10.8956 10.4667 11.1226 9.08436 11.4628L8.55902 11.5935V11.5935ZM10.6684 4.22149C10.3697 4.22149 10.1057 4.28683 9.88302 4.41483C8.77902 5.05083 8.58435 7.26549 9.34435 10.2548C10.5324 9.99216 11.731 9.80416 12.939 9.69883C13.6384 8.7069 14.4013 7.76136 15.223 6.86816C13.4857 5.17883 11.8457 4.22149 10.6684 4.22149V4.22149ZM21.331 26.8668C19.747 26.8668 17.7124 25.6722 15.6004 23.5042L15.223 23.1162L15.6004 22.7295C16.586 21.7029 17.4872 20.5985 18.295 19.4268L18.4444 19.2135L18.703 19.1882C20.1227 19.0787 21.531 18.8526 22.9137 18.5122L23.4404 18.3788L23.5884 18.9002C24.6844 22.7748 24.3524 25.5562 22.671 26.5242C22.2628 26.7556 21.8002 26.8738 21.331 26.8668V26.8668ZM16.7777 23.1068C18.5137 24.7962 20.155 25.7548 21.331 25.7548C21.631 25.7548 21.895 25.6882 22.1164 25.5615C23.2217 24.9242 23.4177 22.7055 22.655 19.7162C21.4684 19.9802 20.2684 20.1695 19.0577 20.2748C18.3613 21.2683 17.5996 22.2144 16.7777 23.1068V23.1068Z"
      fill="#344054"
    />
    <path
      d="M23.4407 11.5933L22.914 11.4613C21.5314 11.12 20.1231 10.893 18.7033 10.7826L18.4447 10.7599L18.2953 10.5439C17.4886 9.37271 16.5874 8.26947 15.6007 7.24527L15.2233 6.85727L15.6007 6.4706C18.374 3.62527 21.022 2.4986 22.6713 3.4506C24.354 4.41993 24.6887 7.19727 23.5887 11.0733L23.4407 11.5933ZM19.058 9.6986C20.33 9.81193 21.5353 10.0026 22.6553 10.2546C23.4167 7.26527 23.222 5.0506 22.1167 4.41327C21.018 3.77727 18.95 4.7506 16.778 6.86793C17.5993 7.76127 18.362 8.70527 19.058 9.6986ZM10.6687 26.8666C10.1995 26.8733 9.73701 26.755 9.32866 26.5239C7.64466 25.5559 7.31133 22.7759 8.41133 18.8999L8.55533 18.3786L9.08199 18.5119C10.3673 18.8359 11.7833 19.0639 13.2927 19.1879L13.5513 19.2133L13.702 19.4266C14.5073 20.5973 15.4087 21.7026 16.3953 22.7293L16.774 23.1159L16.3953 23.5039C14.2847 25.6733 12.25 26.8666 10.6687 26.8666V26.8666ZM9.34466 19.7173C8.58466 22.7066 8.77933 24.9253 9.88333 25.5626C10.9833 26.1893 13.0447 25.2226 15.2233 23.1066C14.4019 22.2131 13.639 21.2676 12.9393 20.2759C11.7301 20.169 10.5293 19.9824 9.34466 19.7173V19.7173Z"
      fill="#344054"
    />
    <path
      d="M16.0009 20.4173C15.0849 20.4173 14.1449 20.3786 13.2009 20.3L12.9395 20.2773L12.7889 20.0613C12.2565 19.2958 11.7557 18.5089 11.2875 17.7026C10.8228 16.8941 10.3909 16.0672 9.99286 15.224L9.88086 14.988L9.99286 14.7493C10.3915 13.9066 10.8222 13.08 11.2875 12.272C11.7502 11.4773 12.2555 10.6826 12.7889 9.91195L12.9395 9.69862L13.2009 9.67462C15.0634 9.51862 16.9357 9.51862 18.7982 9.67462L19.0569 9.69995L19.2075 9.91328C20.2739 11.445 21.2105 13.063 22.0075 14.7506L22.1182 14.9893L22.0075 15.2253C21.2127 16.914 20.276 18.5323 19.2075 20.0626L19.0569 20.2786L18.7982 20.3013C17.8569 20.3773 16.9155 20.4173 16.0009 20.4173V20.4173ZM13.5555 19.212C15.2022 19.336 16.7995 19.336 18.4449 19.212C19.3634 17.8669 20.1799 16.4549 20.8875 14.988C20.1824 13.5196 19.3658 12.1075 18.4449 10.764C16.8175 10.6386 15.1829 10.6386 13.5555 10.764C12.6338 12.107 11.8172 13.5192 11.1129 14.988C11.822 16.4541 12.6385 17.866 13.5555 19.212V19.212Z"
      fill="#344054"
    />
  </svg>
);

export default NavReact;
